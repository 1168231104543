import { HTTP } from '@/libs/https.js';
import config from '@/libs/config.js';

class ChatPPTModel extends HTTP {
  createOutline (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.CHAT_CREATE_OUTLINE,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    })
  }
  getPPTTemplate (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.CHAT_GET_PPT_TEMPLATE,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    })
  }
  getCreatePdf (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.CHAT_CREATE_PDF,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    })
  }
  downloadPdf (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.CHAT_DOWNLOAD_PDF,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    })
  }

  chatGetList (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.CHAT_GET_LIST,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    })
  }
}

export { ChatPPTModel };
